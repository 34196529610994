<template>
  <b-container>
    <b-card class="invision-card elevate-2 invision-top borderless">
      <b-card-header> Selecione a unidade para continuar </b-card-header>
      <div class="mt-4">
        <b-form>
          <b-form-group label="Lista de unidades" label-for="unidade">
            <b-form-select
              name="unidade"
              labelKey="nome"
              class="invision-input"
              v-model="id_unidade"
              :disabled="isBusy || !genericData.unidade"
              :options="options"
              ref="selectUnidade"
              @input="inputUnidade"
            >
              <template slot="first">
                <b-form-select-option :value="null">
                  -- Por favor, selecione uma opção --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="hasToChooseSubSetor"
            label="Lista de áreas"
            label-for="subSetor"
          >
            <GenericSelect
              ref="selectSubSetor"
              name="subSetor"
              labelKey="nome"
              v-model="id_sub_setor"
              route="subSetor"
              :disabled="isBusy"
              :customFilters="subSetorCustomFilters"
              @input="inputSubSetor"
            ></GenericSelect>
          </b-form-group>
        </b-form>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import loginService from '@/services/login';
import GenericSelect from '@/components/Form/GenericSelect';
import unidadeService from '@/services/unidade';

export default {
  components: {
    GenericSelect,
  },
  data() {
    return {
      id_unidade: null,
      id_sub_setor: null,
      isBusy: false,
      options: [],
    };
  },
  computed: {
    ...mapState(['genericData']),
    hasToChooseSubSetor() {
      if (!this.genericData.subSetor || !this.genericData.subSetor.length) return false;
      return (
        !loginService.verifyPermissions(['r_visualizar'])
        && loginService.verifyPermissions(['rw_registro_entrada'])
      );
    },
    subSetorCustomFilters() {
      if (!this.hasToChooseSubSetor || !this.id_unidade) return {};
      return { id_unidade: this.id_unidade };
    },
    unidadesLength() {
      return this.genericData && this.genericData.unidade && this.genericData.unidade.length;
    },
  },
  watch: {
    unidadesLength(v) {
      this.checkIfHasDefault(v);
    },
  },
  async created() {
    this.unidades = await unidadeService.getUnidadesUsuario();
    if (this.unidades.length === 1 && !this.hasToChooseSubSetor) this.inputUnidade(this.unidades[0].id_unidade);
    else {
      this.options = this.unidades.map((unid) => ({
        value: unid.id_unidade,
        text: unid.nome,
      }));
      this.options.sort((a, b) => {
        if (a.text.toUpperCase() < b.text.toUpperCase()) return -1;
        return 1;
      });
    }
  },
  mounted() {
    this.checkIfHasDefault(this.genericData && this.genericData.unidade);
  },
  methods: {
    checkIfHasDefault(unidade) {
      // TODO Retirar hardcoded
      if (
        unidade
        && !loginService.verifyAreas(['cme'])
        && loginService.verifyAreas(['opme'])
      ) {
        this.inputUnidade(1);
      }
      if (
        unidade
        && !loginService.verifyAreas(['cme'])
        && loginService.verifyAreas(['spr'])
      ) {
        this.inputUnidade(3);
      }
      //Setar no caso de hospitais com apenas uma unidade Ex. MonteSinai, Thymos
      if (unidade && unidade.length === 1) {
        this.inputUnidade(unidade[0].id_unidade);
      }
    },
    inputUnidade(value) {
      const unidade = this.genericData.unidade
        .find((item) => item.id_unidade === value);
      loginService.setUnidade(unidade);
      if (!this.hasToChooseSubSetor) {
        if (!(loginService.verifyPermissions(['r_visualizar']) && loginService.verifyAreas(['cme']))) {
          this.$router.replace('/material');
        } else {
          this.$router.replace(this.$route.params.next || '/');
        }
      }
    },
    inputSubSetor(value) {
      const subSetor = this.genericData.subSetor
        .find((ss) => ss.id_sub_setor === value);
      this.$router.replace({
        path: `/Processamento/${subSetor.chave}`,
        query: { justLoggedIn: true },
      });
    },
  },
};
</script>
